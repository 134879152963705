'use client';

import { showToast } from '@spherelabs/react-private/components/tailwind/AppToast';

import * as Tooltip from '@radix-ui/react-tooltip';
import { useTranslation } from 'react-i18next';

const email = 'security@spherepay.co';
interface ReportVulnerabilityLinkProps {
  className: string;
}

export function ReportVulnerabilityLink({
  className,
}: ReportVulnerabilityLinkProps) {
  const { t } = useTranslation();

  const handleReportClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(email);
    showToast({
      text: 'Email copied to clipboard!',
      mode: 'success',
    });
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            type='button'
            className={className}
            onClick={handleReportClick}
          >
            {t('footer.reportVulnerability')}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className='bg-[rgb(16,24,40)] text-[rgb(229,231,235)] p-2 rounded shadow-lg'
            sideOffset={5}
          >
            {email}
            <Tooltip.Arrow className='fill-[rgb(16,24,40)]' />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
